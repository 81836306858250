/* tree  */
ul.jqtree-tree .jqtree-element {
  position: relative;

  /* Store - new css */
  padding: 7px;
  border-radius: 4px;
}

ul.jqtree-tree li {
  /* Store - new css */
  border: 1px solid #143546;
  margin: 7px;
  border-radius: 4px;
}

ul.jqtree-tree span.jqtree-border {
  /* Store - new css */
  padding: 7px;
  border-radius: 4px;
}

/* tree ends */

.has-error {
  color: orangered;
}

.dropzone .dz-preview .dz-image {
  width: 250px;
  height: 250px;
}

.delete-image {
  position: absolute;
  top: 5px;
  right: 20px;
  display: none;

}

.thumb {
  &:hover {
    .delete-image {
      display: block;
    }
  }
}