.boutique-backend {
  .form-control {
    margin-bottom: 10px;
  }
  textarea {
    resize: none;
  }
}

.schedule-table {
  width: 100%{}
  th {
     text-align: center;
  }
}

.schedule-rows td {
  width: 80px;
  height: 30px;
  margin: 3px;
  padding: 5px;
  background-color: #eee;
  cursor: pointer;
}

.schedule-rows td:first-child {
  background-color: transparent;
  text-align: center;
  position: relative;
  //top: -12px;
}

.schedule-rows td[data-selected],
.schedule-rows td[data-selecting='selecting'] {
  background-color: #C1FC9A;
  border: 1px solid #4f63ff;
}

.schedule-rows td[data-selecting='deselecting'] {
  background-color: #FFDAD6;
}

.schedule-rows td[data-disabled] {
  opacity: 0.55;
}
.jFiler-input-dragDrop{
  width: 100%!important;
}
