@import "variables";
@import "mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/_bootstrap";
//@import "bootstrap-sass";
@import "node_modules/font-awesome/scss/font-awesome";
@import "node_modules/jqtree/jqtree.scss";
@import "node_modules/select2/src/scss/core";
@import "node_modules/select2-bootstrap-theme/src/select2-bootstrap";
@import "node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker3.standalone";

@import "sb-admin-2";
@import "categories";
@import "boutiques";


.drag-handle {
  margin-right: 10px;
  font: bold 20px Sans-Serif;
  color: #5F9EDF;
  display: inline-block;
  cursor: move;
  cursor: -webkit-grabbing;
}

.glyphicon-move {
  cursor: pointer;
}

.material-switch > input[type="checkbox"] {
  display: none;
}

.material-switch > label {
  cursor: pointer;
  height: 0;
  position: relative;
  width: 40px;
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position: absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}

.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}

.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}

.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.checkbox-container {
  height: 20px;
}
